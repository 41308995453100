import React, { useRef, useState } from "react";
import { Container } from "react-bootstrap";
import messionIcon from "../../assets/icons/missionIcon.svg";
import visionIcon from "../../assets/icons/visionIcon.svg";
import maskIcon from "../../assets/icons/Mask2.png";
import { Carousel, Row, Col } from "antd";
import DetectWindowSize from "../../helpers/DetectWindowSize";

function AboutUs() {
  const [breakpointData, setBreakpointData] = useState(null);
  // console.log("breakpointData", breakpointData);

  const handleAfterChange = (current) => {
    if (current > 3) {
      carouselRef.current.goTo(0);
    }
  };

  const carouselRef = useRef(null);
  return (
    <section className='mct-section mct-section-aboutus'>
      <DetectWindowSize getBreakPointData={(data) => setBreakpointData(data)} />
      <Container>
        <div className='about-us'>
          <Row>
            <Col span={24}>
              <div className='mct-section-title'>About Us</div>
            </Col>{" "}
            <Col span={24}>
              {" "}
              <p>
                Using the Founder’s{" "}
                <span>(Maged Taha – Mechanical Engineer)</span> long track
                record experience in power, oil & gas and heavy industries
                sectors in EGYPT and Middle East, as well as utilizing his
                leading resources network, we have started Mechanical C&T
                company to deliver a wide range of services aiming to support
                our clients in reaching their goals optimally while focusing
                more on quality and precision perspectives.
              </p>
            </Col>
          </Row>

          <div className='about-us__content'>
            <div className='about-us__mesion-vesion'>
              <div className='about-us__mes-veg'>
                <div className='about-us__card-icon'>
                  <img src={messionIcon} alt='mession icon' />
                </div>
                <div className='blue-title'>Our Mission</div>
                <p className='about-us__card-parag'>
                  Specifying optimum products/ solutions to deliver successful
                  energy projects.
                </p>
              </div>
              <div className='about-us__mes-veg'>
                <div className='about-us__card-icon'>
                  <img src={visionIcon} alt='mession icon' />
                </div>
                <div className='blue-title'>Our Vision</div>
                <p className='about-us__card-parag'>
                  The best partner to count on for energy services.
                </p>
              </div>
            </div>
            <div className='about-us__carousel-content'>
              <div className='about-us__contents'>
                {/* <div className="about-us__icon">
                  <img src={maskIcon} alt="mession icon" />
                </div> */}
                <Carousel
                  className='about-us__carousel-antd'
                  ref={carouselRef}
                  autoplay
                  autoplaySpeed={10000}
                  afterChange={handleAfterChange}
                  dotPosition='bottom'
                  dots={{ className: "custom-dots" }}
                >
                  <div className='about-us__carousel-parag  '>
                    {/* <span className="blue-title">
                      A Brief About The Founder Experience
                    </span> */}
                    <p className='about-us__parag'>
                      <div className='about-us__icon'>
                        <img src={maskIcon} alt='mession icon' />
                      </div>
                      <span className='blue-title'>
                        A Brief About The Founder Experience <br />
                      </span>
                      Maged Taha Holds a B. Sc. in Mechanical Power Engineering
                      “Ain Shams University - 2003” and a two years Professional
                      Program Certificate in Environmental
                      Engineering/Management (American University in Cairo “AUC”
                      (2005-2007). Has over 21 years of experience in Detail
                      Engineering, Project Management, Business Development &
                      Tendering related to Power, Industrial and Oil & Gas
                      Sectors.
                    </p>
                  </div>
                  <div className='about-us__carousel-parag'>
                    <p className='about-us__parag'>
                      <div className='about-us__icon'>
                        <img src={maskIcon} alt='mession icon' />
                      </div>
                      •&nbsp; Former Business Development Director – ECS Co.
                      (2022 -2023) (Experience in developing & securing Mega
                      projects related to energy and Oil & Gas sectors) <br />
                      •&nbsp; Former Business Development Director , Partner and
                      Board Member –The Energy Group “TEG” and EGYPTROL Co.
                      (2011 – 2022) (Experience in establishing and operating
                      varieties of engineering services related to Energy and
                      Oil & Gas Sectors “EPC, Detail Engineering , Project
                      Management, Commissioning and Start up, O&M…etc”)
                    </p>
                  </div>
                  <div className='about-us__carousel-parag'>
                    <p className='about-us__parag'>
                      <div className='about-us__icon'>
                        <img src={maskIcon} alt='mession icon' />
                      </div>
                      •&nbsp; Former Sales Manager – NEM/Siemens Dubai Office
                      (2013 -2014) (Experience in Business Development related
                      to GCC and ME power generation sectors ) <br />
                      •&nbsp; Former Mechanical Team Leader – PSP Elsewedy (2010
                      -2011) (Experience in Detailed Engineering Management and
                      EPC Project Management related to Power Generation Sector)
                    </p>
                  </div>
                  <div className='about-us__carousel-parag'>
                    <p className='about-us__parag'>
                      <div className='about-us__icon'>
                        <img src={maskIcon} alt='mession icon' />
                      </div>
                      •&nbsp; Former Project Engineer and Senior Mechanical
                      Engineer – Air Liquide Egypt (2008 – 2010) (Experience in
                      Project Management, Detailed Engineering, Procurement &
                      Construction Management related to Industrial Gases
                      Sector) <br />
                      •&nbsp; Former Mechanical Engineer – Pgesco/Bechtel (2004
                      – 2008) (Experience in Detailed Engineering and
                      Procurement Management related to Power Generation Sector)
                      <br />
                      •&nbsp; Technical Sales Engineer Absorption Chillers (2003
                      – 2004)
                    </p>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default AboutUs;
